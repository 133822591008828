#note_main {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
}

#note_title_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#note_body_container {
  /* display: flex; */
  display: none;
  flex-direction: column;
  height: 100vh;
}

#noet_saved_and_spinner {
  padding-left: 10px;

  /* disable text selection */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
