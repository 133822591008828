#settings_page {
  height: 100vh;
  /* background-color: red; */
}

#logout_button {
  background-color: rgb(0, 0, 0);
  color: aliceblue;
  font-size: large;
  padding: 15px;
  margin: 10px;
  border-color: rgba(240, 248, 255, 0);
  border-radius: 8px;
}
