#description-learn-more-button a:link,
#description-learn-more-button a:visited {
  color: inherit;
  text-decoration: none;
}

#description-learn-more-button a:hover {
  color: inherit;
}

#description-learn-more-button a:active {
  color: inherit;
}

#description-and-undraw {
  /* background-color: red; */
  display: grid;
  grid-template-columns: 6fr 5fr;
}

#description {
  display: flex;

  align-items: center;
}
#description-button-contander {
  margin: 10px;

  display: grid;
  grid-template-columns: auto 1fr;
}
#description-get-started-button {
  margin: 10px;
  background-color: #472eb7;
  color: white;
  padding: 13px;
  width: fit-content;
  border-radius: 15px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#description-learn-more-button {
  margin: 10px;
  background-color: #575757;
  color: white;
  padding: 13px;
  width: fit-content;
  border-radius: 15px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#description-header {
  color: #472eb7;
}
#undraw {
  height: 70vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
#undraw-png {
  height: 300px;
}

@media screen and (max-width: 900px) {
  #undraw {
    display: none;
  }
  #description-and-undraw {
    display: grid;
    grid-template-columns: auto;
  }
  #description {
    min-height: 60vh;
  }
}
