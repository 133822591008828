#loginpage_lite {
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: red; */
  height: 100vh;
}
#loginpage_drak {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(34, 34, 34);
  height: 100vh;
  color: rgb(255, 255, 255);
}
#del_786875 {
  /* background-color: blue; */
  display: grid;
  grid-template-columns: auto;
  /* padding: 10px; */
}

#login_user_name {
  font-size: x-large;
  margin: 7px;
  padding: 10px;
}
#login_password {
  font-size: x-large;
  margin: 7px;
  padding: 10px;
}
#login_button {
  font-size: x-large;
  margin: 7px;
  padding: 10px;
}
