#Search_input {
  /* background-color: rgba(143, 143, 143, 0.193); */
  background-color: blue;
  padding: 10px;
  margin: 10px;
  margin-top: 0px;

  border-radius: 8px;
  border: 1px solid rgb(179, 179, 179);

  /* color: rgba(0, 242, 68, 0.374); */

  display: grid;
  grid-template-columns: 1fr;
}
#Search_input_icon {
  /* background-color: red; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding-right: 7px;
}
