.todo_card {
  margin: 6px;
  padding: 3px;
  background-color: rgb(0, 0, 0);
  color: aliceblue;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
