.note_card {
  background-color: black;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 7px;
}

#note_title_card_container {
  height: 100%;
  overflow: scroll;
}
