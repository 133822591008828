#homepage_app {
  height: 100vh;
  overflow: scroll;
}
#sldhkfhyu {
  /* background-color: rgba(139, 139, 139, 0.048); */

  height: 37vh;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  text-align: center;
}
