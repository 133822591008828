#add_contander {
  /* background-color: red; */
  background-color: rgba(122, 121, 121, 0.205);
  height: 35vh;
  margin: 10px;

  /* display: grid;
  grid-template-columns: auto auto auto auto auto; */
}
#add_contander_input {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
}
