.mobile_todo_card {
  /* background-color: plum;
    margin: 10px;
    padding: 10px;
    border-radius: 10px; */

  /* font-size: x-large; */

  margin: 6px;
  padding: 8px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  display: grid;

  grid-template-columns: 1fr auto;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
