.contact_maine_card {
  display: grid;
  grid-template-columns: auto auto;
  background-color: rgba(125, 125, 125, 0.27);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}
.contact_left_display {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.contact_note_input {
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  color: inherit;
}
.contact_delete_button {
  background-color: red;
  border-color: rgba(240, 248, 255, 0);
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 10px;
  margin-right: 10px;
}
