.todo_contander {
  height: 100vh;
  overflow: scroll;
}

.todo_top_bar_todo {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 7px;
}

.todo_add_todo_button {
  background-color: rgb(0, 0, 0);
  border-color: rgb(0, 0, 0);
  border-radius: 5px;
  color: rgb(255, 255, 255);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin: 3px;
}

.styled_input_todo {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  color: inherit;

  background-color: rgba(122, 121, 121, 0.205);
  border-radius: 5px;
  padding-left: 7px;
}
