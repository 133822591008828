#App-home-page {
  display: flex;
  justify-content: center;
  color: #575757;

  
}

#home-window {
  width: 100%;
  max-width: 1130px;
}
