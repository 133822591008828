#homepage_bookmarks {
  /* background-color: blue; */
  padding-bottom: 50px;
}

#home_link_holder {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  padding-top: 20px;
}

.home_link_card_container {
  min-height: 30px;
  text-align: center;
}
.home_link_card {
  position: relative;
  background-color: rgba(136, 136, 136, 0.295);
  height: 30px;
  width: 30px;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.homepage_bookmarks_delete_icon {
  background-color: red;
  border-radius: 5px;
  padding: 2px;
  position: absolute;
  right: 0;
  top: 0;
}
.sdfkjgfdg {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
#homepage_add_bookmarks_container_shoe {
  display: flex;
  justify-content: center;
  align-items: center;
}
#homepage_add_bookmarks_container_hide {
  display: none;
}
