.messages {
  border-top: 1px solid rgb(200, 200, 200);
  padding: 10px;

  display: grid;
  grid-template-columns: auto 1fr;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.profile_picture {
  background-color: red;
  height: 28px;
  width: 28px;
  border-radius: 100000px;
  margin-right: 7px;
}
.messages_header {
  display: grid;
  grid-template-columns: auto 1fr;
  /* background-color: blue; */
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.time_stamp {
  color: rgb(161, 161, 161);
  padding-left: 7px;
}
.messages_text {
  padding-top: 4px;
}
