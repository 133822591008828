#homepage_add_bookmarks_card {
  border: 1px solid rgb(0, 0, 0);
  padding: 20px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
}
.homepage_add_bookmarks_card_textinput {
  border: none;
  background: none;
  outline: none;
  font-size: large;
  font-weight: normal;
  color: inherit;

  background-color: rgba(122, 121, 121, 0.205);
  border-radius: 5px;
  padding-left: 7px;
  margin: 5px;
}
#homepage_cancel_and_add_button_container {
  display: grid;
  grid-template-columns: auto 1fr;
}