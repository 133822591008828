#chat_input {
  background-color: rgba(143, 143, 143, 0.193);
  padding: 10px;
  margin: 10px;
  margin-top: 0px;

  border-radius: 8px;
  border: 1px solid rgb(179, 179, 179);

  /* color: rgba(151, 151, 151, 0.374); */

  /* display: grid; */
  /* grid-template-columns: auto 1fr; */
}
#chat_input_icon {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  padding-right: 7px;
}