#contactup {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: 100vh;
  text-align: center;
}

#contactup-two {
  max-width: 1130px;
}
