#mein_app_container_lite {
  display: flex;
  justify-content: center;
  align-items: center;
}
#mein_app_container_datk {
  background-color: rgb(34, 34, 34);
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
}

#App {
  width: 100%;
  max-width: 1130px;

  display: grid;
  grid-template-columns:  auto 1fr;
}

.left_card {
  background-color: rgba(125, 125, 125, 0.27);
  padding: 7px;
  margin: 7px;
  border-radius: 7px;

  font-size: larger;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
