.two-card-main-card {
  background-color: rgb(255, 255, 255);
  margin: 10px;
  border-radius: 10px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  overflow: visible;
}
@media screen and (max-width: 1100px) {
  .two-card-main-card {
    padding-bottom: 30px;
  }
}

.undraw-card-png {
  /* width: 100px; */
  height: 100px;
}

.two-card-title {
  padding-top: 10px;
  padding-bottom: 10px;
}

.two-card-disicein {
  color: #575757;
}
