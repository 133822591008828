#note_add_note_box {
  padding-left: 13px;
  padding-right: 13px;
}
#add_note_button{
  padding-left: 6px;
  padding-right: 6px;
}

#note_add_text_box{
  background-color: rgba(255, 0, 0, 0);
  color: inherit;
}