#top-bar {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: grid;
  grid-template-columns: 1fr auto;
}
#sfdfsdfsdf {
  display: flex;
  align-items: center;

  color: #472eb7;
}
#top-bar-links {
  display: grid;
  grid-template-columns: auto auto auto;

  display: flex;
  justify-content: center;
  align-items: center;
}
.top-bar-link {
  margin: 10px;
  padding: 10px;
}

@media screen and (max-width: 500px) {
  #top-bar {
    display: grid;
    grid-template-columns: auto;
  }

  #top-bar-links {
    display: flex;
    justify-content: flex-start;
  }
}
