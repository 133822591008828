.input-container {
  /* background-color: plum; */
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.full-width-input {
  width: calc(
    100% - 20px
  ); /* Take the full width of the container, considering padding */
  box-sizing: border-box;
  padding: 8px; /* Adjust the padding as needed */
  border: 1px solid #ccc; /* Add a border for better visibility */
  border-radius: 4px; /* Optional: Add border-radius for rounded corners */
}

.mobile_todo_main_card {
  /* background-color: red; */
  min-height: 30vh;
  margin-top: 1px;
  font-size: x-large;
}
.mobile_todo_input {
  /* background-color: blue; */
  display: grid;
  grid-template-columns: 1fr auto;
}
.mobile_todo_button{
  /* background-color: aqua; */
  font-size: medium;
}