#add_contander_mobile {
  /* background-color: red;
  background-color: rgba(122, 121, 121, 0.205); */
  /* height: 100%; */
  /* margin: f; */

  /* display: grid;
  grid-template-columns: auto auto auto auto auto; */
  background-color: rgba(122, 121, 121, 0.205);
  margin: 5px;
  padding: 5px;
}
#add_contander_input_mobile {
  display: grid;
  grid-template-columns: auto auto;
}
