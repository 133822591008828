#chat {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#chat_log_container {
  display: flex;
  flex: 1;
}

#chat_log {
  /* background-color: rgb(0, 154, 59); */

  width: 100%;
  height: 100%;
  overflow: scroll;
}

#main_chat_log {
  display: flex;
  flex-direction: column;
}
