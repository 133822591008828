* {
  padding: 0px;
  margin: 0px;
  font-family: Helvetica, Arial, sans-serif;
  overflow: scroll;
  /* overflow-y: hidden; 
  overflow-x: hidden; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  text-decoration: none;
  color: inherit;
  text-decoration: none; /* Optional: Removes the underline by default */
}
a:hover {
  color: blue;
}

*::-webkit-scrollbar {
  display: none;
}

/* TextInputWithPlaceholder.css */
.styled-input {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  color: inherit;
  width: 100%;
  /* background-color: red; */
}

.styled-paragraph {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
