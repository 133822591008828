#etyirguweuifgh {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: 100vh;
  text-align: center;
}

#sfldkhf8u{
  max-width: 1130px;
  /* background-color: red; */
}