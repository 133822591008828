.contact_maine_display {
  height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  /* padding: 10px;
  background-color: red; */
}
#search_spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
#search_spinner_contander {
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-bottom: 10px; */
}
#jdskhgkjfhgkjdshgjkh {
  flex: auto;
  /* background-color: #84848400; */
  padding-bottom: 9px;
  padding-top: 2px;
  padding-left: 8px; /* 
  color: #fff;
  text-align: center; */
  /* padding: 20px; */
}
#contactCard_display {
  height: 100%;
  /* background-color: red; */
  /* flex-direction: row-reverse; */
  /* flex: 1; */
  /* background-color: red; */
}
