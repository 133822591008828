#mobile_add_note {
  /* background-color: red; */

  display: grid;
  grid-template-columns: 1fr auto ;
}

.mobile_note_title_card {
  padding: 10px;
  margin: 10px;
  background-color: rgb(0, 0, 0);
  color: aliceblue;
  border-radius: 10px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#mobile_note {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  height: 100vh; /* Set the height of the container to 100% of the viewport height */
}

#mobile_titlea_and_backButton {
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: auto 1fr auto;
}

#mobile_body_input {
  flex: 1; /* Make the textarea flexible to fill the remaining space */
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: rgba(240, 248, 255, 0);
  color: inherit;
}

#mobile_spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}