.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

#note_body_top_bar {
  display: grid;
  grid-template-columns: 5fr auto 1fr;
}
#note_title_textbox {
  background-color: rgba(255, 0, 0, 0);
  color: inherit;
}
