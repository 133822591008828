.mobile_datk_container {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mobile_lite_container {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top-bar {
  background-color: #88888833;
  /* color: #fff; */
  display: grid;
  grid-template-columns: auto 1fr;
}
#mobile_top_card_name {
  /* background-color: plum; */
  text-align: center;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mobile_datk_content {
  background-color: rgb(29, 29, 29);
  flex-grow: 1;
}

.mobile_lite_content {
  /* background-color: rgb(29, 29, 29); */
  flex-grow: 1;
}