.menu_card_icon {
  color: white;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  margin: 3px;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: center;
  font-size: xx-large;
}
