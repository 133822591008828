#sineup_ {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: 100vh;
}
#sdlfhlkdjf{
  max-width: 1130px;
  text-align: center;
}