#clock_fase {
  font-size: xx-large;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* background-color: red; */
  text-align: center;
}
