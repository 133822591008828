/* #search {
  background-color: blue;
} */

#search_one {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search_one_engine {
  margin: 10px;
  display: flex;
}
