.grey-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align items with space between them */
  /* height: 65vh; */
  height: fit-content;

  color: #472eb7;
  text-align: center;
  background-image: linear-gradient(
    0deg,
    hsl(251deg 60% 45%) 0%,
    hsl(254deg 48% 50%) 21%,
    hsl(256deg 48% 55%) 30%,
    hsl(258deg 47% 60%) 39%,
    hsl(260deg 45% 65%) 46%,
    hsl(261deg 43% 70%) 54%,
    hsl(262deg 40% 75%) 61%,
    hsl(262deg 34% 81%) 69%,
    hsl(263deg 24% 86%) 79%,
    hsl(0deg 0% 91%) 100%
  );

  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;
}

#two-card-main-card-contander {
  /* background-color: rgba(255, 0, 0, 0.356); */
  flex-grow: 1; /* Allow this element to grow and fill remaining space */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1100px) {
  #two-card-main-card-contander {
    grid-template-columns: 1fr 1fr;
  }

  .grey-card {
    /* height: 132vh; */
    height: fit-content;
    /* background-color: red; */
  }
}

@media screen and (max-width: 750px) {
  #two-card-main-card-contander {
    grid-template-columns: auto;
  }

  .grey-card {
    /* height: 265vh; */
    height: fit-content;
    /* background-color: blue; */
  }
}
