#homepage_toolbar {
  /* background-color: red; */

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: grid;
  grid-template-columns: auto auto auto 1fr;

  font-size: large;

  /* padding-bottom: 20px; */
}

#homepage_toolbar_delete_button {
  background-color: rgba(125, 125, 125, 0.27);
  padding: 7px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-radius: 5px;
  margin-right: 7px;

  display: flex;
  justify-content: center;
  align-items: center;
}

#homepage_toolbar_directory_name {
  background-color: rgba(125, 125, 125, 0.27);
  padding: 7px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-radius: 5px;
  margin-right: 7px;

  white-space: nowrap;
  overflow: scroll;
}

#homepage_toolbar_edit_button {
  background-color: rgba(125, 125, 125, 0.27);
  padding: 7px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-radius: 5px;
  margin-right: 7px;
}
#homepage_toolbar_add_button {
  background-color: rgba(125, 125, 125, 0.27);
  padding: 7px;
  padding-top: 1px;
  padding-bottom: 3px;
  border-radius: 5px;
  margin-right: 7px;
}
